exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-affiliate-tsx": () => import("./../../../src/pages/affiliate.tsx" /* webpackChunkName: "component---src-pages-affiliate-tsx" */),
  "component---src-pages-competition-tsx": () => import("./../../../src/pages/competition.tsx" /* webpackChunkName: "component---src-pages-competition-tsx" */),
  "component---src-pages-edit-product-information-tsx": () => import("./../../../src/pages/edit-product-information.tsx" /* webpackChunkName: "component---src-pages-edit-product-information-tsx" */),
  "component---src-pages-eu-funding-tsx": () => import("./../../../src/pages/eu-funding.tsx" /* webpackChunkName: "component---src-pages-eu-funding-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-management-tsx": () => import("./../../../src/pages/order-management.tsx" /* webpackChunkName: "component---src-pages-order-management-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-product-category-mapping-tsx": () => import("./../../../src/pages/product-category-mapping.tsx" /* webpackChunkName: "component---src-pages-product-category-mapping-tsx" */),
  "component---src-pages-product-content-enrichment-tsx": () => import("./../../../src/pages/product-content-enrichment.tsx" /* webpackChunkName: "component---src-pages-product-content-enrichment-tsx" */),
  "component---src-pages-product-information-export-tsx": () => import("./../../../src/pages/product-information-export.tsx" /* webpackChunkName: "component---src-pages-product-information-export-tsx" */),
  "component---src-pages-product-information-import-tsx": () => import("./../../../src/pages/product-information-import.tsx" /* webpackChunkName: "component---src-pages-product-information-import-tsx" */)
}

